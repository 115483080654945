import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 逻辑库区 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11248
 */

export function abstractZonePage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/abstract_zone/page', data, {
    params,
  });
}
/**
 * @description: 逻辑库区 新增
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11240
 */
export function abstractZoneInsert(data) {
  return wmsPlusHttp.post('/warehouse_foundation/abstract_zone/insert', data);
}
/**
 * @description: 逻辑库区 修改
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11242
 */
export function abstractZoneUpdate(data) {
  return wmsPlusHttp.post('/warehouse_foundation/abstract_zone/update', data);
}
/**
 * @description: 逻辑库区 根据ID获取
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11244
 */

export function abstractZoneGet(params) {
  const { id } = params;
  return wmsPlusHttp.get(`/warehouse_foundation/abstract_zone/get?id=${id}`);
}
/**
 * @description:逻辑库区 根据ID删除
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11246
 */

export function abstractZoneDelete(params) {
  const { id } = params;
  return wmsPlusHttp.get(`/warehouse_foundation/abstract_zone/delete?id=${id}`);
}
/**
 * @description: 库位管理 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11180
 */
export function storehousePage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/storehouse/page', data, {
    params,
  });
}
/**
 * @description: 库区信息表 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11164
 */

export function warehouseZonePage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/zone/page', data, {
    params,
  });
}
