<template>
  <div class="header-component">
    <el-form
      ref="ruleForm"
      :model="formData"
      label-position="top"
    >
      <el-row>
        <el-col :span="4">
          <el-form-item label="库位编码：" prop="storageLocationCode">
            <el-input v-model="formData.storageLocationCode" placeholder="逻辑区编码" />
          </el-form-item>
        </el-col>
        <el-col :span="4" :offset="1">
          <el-form-item label="库区类型：" prop="warehouseZoneTypeCode">
            <el-select v-model="formData.warehouseZoneTypeCode" placeholder="--请选择--">
              <el-option
                v-for="item in ZONE_TYPE_CODE"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4" :offset="1">
          <el-form-item label="库区：" prop="warehouseAreaId">
            <el-select v-model="formData.warehouseAreaId" placeholder="--请选择--">
              <el-option
                v-for="item in warehouseZonelist"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4" :offset="1">
          <el-form-item label="巷道：" prop="col">
            <el-input v-model="formData.col" placeholder="" />
          </el-form-item>
        </el-col>
        <el-col :span="4" :offset="1">
          <el-form-item label="货架类型：" prop="typeCode">
            <el-select v-model="formData.typeCode" placeholder="--请选择--">
              <el-option
                v-for="item in STOREHOUSE_TYPE_CODE"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="button">
      <nh-button @click="handleQueryFrom">
        查询
      </nh-button>
      <nh-button @click="resetForm">
        重置
      </nh-button>
      <slot />
    </div>
  </div>
</template>
<script>
import { warehouseZonePage } from '../../api';
import mixin from '../../mixin';

export default {
  name: 'HeaderComponent',
  mixins: [mixin],
  emits: ['handleQueryFrom'],

  data() {
    return {
      warehouseZonelist: [],
      formData: {
      },
    };
  },
  created() {
    this.$nextTick(() => {
      this.getSelectList();
    });
  },
  methods: {
    async getSelectList() {
      warehouseZonePage({ page: 1, size: 1000 }, { }).then((resp) => {
        this.warehouseZonelist = resp.records.map((item) => ({
          label: item.zoneName,
          value: item.id,
        }));
      });
    },
    handleQueryFrom() {
      this.$emit('handleQueryFrom', { ...this.formData });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.handleQueryFrom();
    },
  },
};
</script>
