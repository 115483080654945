import store from '@/store';

export default function getSerchFields() {
  const typeCodeList = store.dispatch('app/searchDictionaryByCode', 'STOREHOUSE_TYPE_CODE').then((resp) => resp.map((item) => ({
    value: item.code,
    label: item.name,
  })));
  const ZONE_TYPE_CODE = store.dispatch('app/searchDictionaryByCode', 'ZONE_TYPE_CODE').then((resp) => resp.map((item) => ({
    value: item.code,
    label: item.name,
  })));

  return Promise.all([typeCodeList, ZONE_TYPE_CODE]).then((values) => [
    {
      prop: 'selection',
      width: 50,
      type: 'selection',
    }, {
      label: '库位',
      prop: 'storageLocationCode',
    }, {
      label: '库区',
      prop: 'warehouseAreaName',
    }, {
      label: '通道',
      prop: 'aisle',
    }, {
      label: '库区类型',
      prop: 'warehouseZoneTypeCode',
      filter: true,
      arr: values[1],
    }, {
      label: '货架类型',
      prop: 'typeCode',
      filter: true,
      arr: values[0],
    },
  ]);
}
