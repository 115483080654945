<template>
  <pl-block>
    <PlForm
      v-model="formData"
      mode="search"
      :fields="serchFields(typeCodeList)"
      @submit="handleQueryFrom"
      @reset="handleQueryFrom"
    />
  </pl-block>
  <pl-block>
    <nh-pro-table-header>
      <template #headerRight>
        <nh-button
          type="primary"
          plain
          :track="{
            trackName: '基础数据/仓库相关/逻辑区管理/[新建]'
          }"
          @click="headleAddEquip"
        >
          新建
        </nh-button>
      </template>
    </nh-pro-table-header>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
    >
      <template #typeCode="{row}">
        <span>
          {{ filterLabel(row.typeCode, typeCodeList) }}</span>
      </template>
      <template #action="{row}">
        <nh-button type="text" @click="editEquip(row)">
          编辑
        </nh-button>
        <nh-button type="text" @click="removeLogic(row)">
          删除
        </nh-button>
        <nh-button type="text" @click="handleLogicSet(row)">
          库位设置
        </nh-button>
      </template>
    </PlTable>
  </pl-block>

  <edit-dialog ref="childEdit" @onSuccess="getTableData" />
  <set-location-dialog ref="childSetLocation" @onSuccess="getTableData" />
</template>
<script>
import loadingMixin from '@thales/loading';
import mixin from './mixin';

import EditDialog from './components/editDialog.vue';
import setLocationDialog from './components/setLocationDialog.vue';
import { TABLECOLUMN, serchFields } from './fileds';
import { abstractZonePage, abstractZoneDelete } from './api';

const moment = require('moment');

export default {
  name: 'StorageLogic',
  components: {
    EditDialog,
    setLocationDialog,
  },
  mixins: [loadingMixin, mixin],
  data() {
    return {
      TABLECOLUMN,
      serchFields,
      tableData: [],
      formData: {},
      loading: {
        getTableData: false,
      },
    };
  },
  watch: {
    pagination() {
      this.getTableData();
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    filterLabel(val, arr) {
      const findArr = arr.find((item) => item.value === val);
      return findArr ? findArr.label : '';
    },
    handleQueryFrom() {
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await abstractZonePage(pagination, this.formData);
      this.tableData = resp.records.map((item) => {
        const time = item.updateTime || item.createTime;
        return {
          ...item,
          time: time ? moment(time).format('YYYY/MM/DD HH:mm:SS') : '',
        };
      });
      this.pagination.total = resp.total;
    },
    headleAddEquip() {
      this.$refs.childEdit.init();
    },
    editEquip(row) {
      this.$refs.childEdit.init(row);
    },
    async removeLogic(row) {
      await this.$confirm(`是否确认删除逻辑区：${row.abstractZoneName}`, '提示信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      });
      await abstractZoneDelete({ id: row.id });
      this.$message({
        type: 'success',
        message: '删除成功!',
      });
      this.getTableData();
    },
    handleLogicSet(row) {
      this.$refs.childSetLocation.init(row);
    },
  },
};
</script>
<style scoped lang="scss">
.action {
  padding: 15px 0;
}

.full-content {
  :deep(.cursor) {
    cursor: pointer;
  }
}

.cursor-color {
  color: aquamarine;
}
</style>
