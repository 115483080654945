import { FormType } from '@/constant/form';

export const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '逻辑区编码',
  prop: 'abstractZoneCode',
  minWidth: 120,
}, {
  label: '逻辑区名称',
  prop: 'abstractZoneName',
  minWidth: 120,
}, {
  label: '逻辑区类型',
  prop: 'typeCode',
  minWidth: 120,
}, {
  label: '库位集合',
  prop: 'warehouseStoreNumber',
  minWidth: 80,
}, {
  label: '编辑人',
  prop: 'updateUserName',
  minWidth: 120,
}, {
  label: '编辑时间',
  prop: 'time',
  minWidth: 180,
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 240,
}];

export function serchFields(typeCodeList) {
  return [
    {
      label: '逻辑区编码',
      prop: 'abstractZoneCode',
      component: FormType.INPUT,
    }, {
      label: '逻辑区名称',
      prop: 'abstractZoneName',
      component: FormType.INPUT,
    }, {
      label: '逻辑区类型',
      prop: 'typeCode',
      component: FormType.SELECT,
      options: typeCodeList,
    },
  ];
}
