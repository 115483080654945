<template>
  <div class="header-component">
    <nh-dialog
      v-model="dialogVisible"
      title="逻辑区-库位设置"
      custom-class="custom-dialog_1200"
      close="closeDialog"
      :track="{
        name_zh: '基础数据/仓库相关/逻辑区管理/弹窗-库位设置',
        trackName: $route.path
      }"
    >
      <div class="title">
        <el-form
          ref="ruleForm"
          :model="headerData"
          label-width="100px"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="逻辑区编码：">
                <el-input v-model="headerData.abstractZoneCode" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="6" :offset="1">
              <el-form-item label="逻辑区名称：">
                <el-input v-model="headerData.abstractZoneName" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="6" :offset="1">
              <el-form-item label="逻辑区类型：">
                <el-select v-model="headerData.typeCode" disabled>
                  <el-option
                    v-for="item in typeCodeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <header-component class="header" @handleQueryFrom="handleQueryFrom" />
      <div class="body">
        <div class="left-table">
          <div class="table-header">
            待分配库位列表：{{ pagination.total }}
          </div>
          <transfer-table
            ref="leftTable"
            v-model:select-num="selectLNum"
            :load="true"
            :table-data="leftTable"
            @onLoadTable="onLoadTable"
          />
        </div>
        <div class="btn">
          <div class="add-btn">
            <nh-button @click="handleAddLocation">
              添加{{ selectLNum }}
            </nh-button>
          </div>
          <div class="remove-btn">
            <nh-button @click="handleRemoveLocation">
              移除{{ selectRNum }}
            </nh-button>
          </div>
        </div>
        <div class="right-table">
          <div class="table-header">
            已绑定库位列表：{{ rightTable.length }}
          </div>
          <transfer-table
            ref="rightTable"
            v-model:select-num="selectRNum"
            :table-data="rightTable"
          />
        </div>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <nh-button
            type="primary"
            :loading="loading.headleSubmitForm"
            @click="headleSubmitForm"
          >
            保存
          </nh-button>
        </div>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import mixin from '../mixin';
import HeaderComponent from './components/header.vue';
import TransferTable from './components/transferTable.vue';
import { storehousePage, abstractZoneGet, abstractZoneUpdate } from '../api';

export default {
  name: 'SetLoactionDialog',
  components: { HeaderComponent, TransferTable },
  mixins: [loadingMixin, mixin],
  emits: ['onSuccess'],
  data() {
    return {
      dialogVisible: false,
      loading: {
        headleSubmitForm: false,
      },
      headerData: {},
      searchForm: {},
      selectLNum: 0,
      leftTable: [],
      pagination: {
        page: 1,
        size: 10,
      },
      selectRNum: 0,
      rightTable: [],
      rules: {
        code: [
          { required: true, message: '请输入设备编码', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '请选择设备类型', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '请选择设备属性', trigger: 'change' },
        ],
      },
    };
  },
  methods: {
    async init(data) {
      this.dialogVisible = true;
      if (data) {
        this.headerData = { ...data };
        const resp = await abstractZoneGet({ id: data.id });
        this.rightTable = resp.warehouseStorehouseResps.map((item) => ({
          ...item,
          aisle: `${item.col}-${item.warehouseRow}-${item.layer}`,
        }));
        this.handleQueryFrom({});
      }
    },
    handleQueryFrom(data) {
      this.searchForm = { ...data };
      this.$refs.leftTable.scrollTop(0);
      this.pagination.page = 1;
      this.getLeftTable();
    },
    onLoadTable() {
      const { page, size, total } = this.pagination;
      if (total && page * size < Number(total)) {
        this.pagination.page += 1;
        this.getLeftTable();
      }
    },
    async getLeftTable() {
      const formData = {
        ...this.searchForm,
        notInIds: this.rightTable.map((item) => item.id),
      };
      const resp = await storehousePage(this.pagination, formData);
      const tableData = resp.records.map((item) => ({
        ...item,
        aisle: `${item.col}-${item.warehouseRow}-${item.layer}`,
      }));
      if (this.pagination.page === 1) {
        this.leftTable = tableData;
      } else {
        const codes = this.leftTable.map(({ storageLocationCode }) => storageLocationCode);
        tableData.forEach((item) => {
          if (!codes.includes(item.storageLocationCode)) {
            this.leftTable.push(item);
          }
        });
      }
      this.pagination = {
        page: resp.page,
        size: resp.size,
        total: resp.total,
      };
    },
    async headleSubmitForm() {
      const data = {
        ...this.headerData,
        warehouseStorehouseIds: this.rightTable.map((item) => item.id),
      };
      await abstractZoneUpdate(data);
      this.$message({
        message: '保存成功！',
        type: 'success',
      });
      this.$emit('onSuccess');
      this.close();
    },

    handleAddLocation() {
      const selectList = this.$refs.leftTable.getSelect();
      this.rightTable.push(...selectList);
      this.leftTable = this.leftTable.filter(({
        storageLocationCode,
      }) => {
        const hasCode = selectList.some((list) => storageLocationCode === list.storageLocationCode);
        return !hasCode;
      });
      this.getLeftTable();
    },
    handleRemoveLocation() {
      const selectList = this.$refs.rightTable.getSelect();
      this.leftTable.push(...selectList);
      this.rightTable = this.rightTable.filter(({
        storageLocationCode,
      }) => !selectList.some((list) => storageLocationCode === list.storageLocationCode));
      this.getLeftTable();
    },
    close() {
      this.dialogVisible = false;
    },
    closeDialog() {
      this.headerData = {};
      this.searchForm = {};
      this.selectLNum = 0;
      this.leftTable = [];
      this.pagination = {
        page: 1,
        size: 10,
      };
      this.selectRNum = 0;
      this.this.$refs.ruleForm.resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.title,
.header {
  border-bottom: 1px solid #ccc;
}

.header {
  padding-bottom: 22px;
}

.body {
  display: flex;

  .left-table,
  .right-table {
    flex: 1 1 400px;
    overflow-x: hidden;

    .table-header {
      padding: 10px;
    }
  }

  .btn {
    flex: 0 0 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > div {
      padding: 10px 0;
    }
  }
}

</style>
