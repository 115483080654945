<template>
  <el-table
    ref="tableData"
    v-infinite-scroll="()=> {}"
    :data="tableData"
    height="300px"
    class="width-100"
    :show-overflow-tooltip="true"
    @selection-change="selectChange"
  >
    <template v-for="item in TABLECOLUMN">
      <el-table-column
        v-if="item.type"
        :key="item.prop"
        :type="item.type"
        :width="item.width"
      />
      <el-table-column
        v-else
        :key="item.prop"
        :prop="item.prop"
        :label="item.label"
        :min-width="item.width"
      >
        <template #default="scope">
          <span v-if="item.filter">{{ filterLabel(scope.row[item.prop], item.arr) }}</span>
          <span v-else>{{ scope.row[item.prop] }}</span>
        </template>
      </el-table-column>
    </template>
  </el-table>
</template>
<script>
import getSerchFields from './fileds';

export default {
  name: 'TransferTable',
  props: {
    load: {
      type: Boolean,
      default: false,
    },
    selectNum: {
      type: Number,
      default: 0,
    },
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ['update:selectNum', 'onLoadTable'],
  data() {
    return {
      TABLECOLUMN: [],
      selection: [],
    };
  },
  created() {
    if (this.load) {
      this.$nextTick(() => {
        this.$refs.tableData.$refs.bodyWrapper.addEventListener(
          'scroll',
          (res) => {
            const height = res.target;
            const { clientHeight, scrollTop, scrollHeight } = height;
            if (clientHeight + scrollTop + 20 > scrollHeight) {
              this.$emit('onLoadTable');
            }
          },
          true,
        );
      });
    }
  },
  mounted() {
    this.getTableColumn();
  },
  methods: {
    async getTableColumn() {
      this.TABLECOLUMN = await getSerchFields();
    },
    scrollTop(num) {
      this.$refs.tableData.$refs.bodyWrapper.scrollTop = num;
    },
    selectChange(selection) {
      this.selection = selection;
      this.$emit('update:selectNum', selection.length);
    },
    getSelect() {
      return this.selection;
    },
    filterLabel(val, arr) {
      const filterArr = arr.find((item) => item.value === val);
      return filterArr ? filterArr.label : '';
    },
  },
};
</script>
<style scoped>
.width-100 {
  width: 100%;
}
</style>
