<template>
  <div class="header-component">
    <nh-dialog
      v-model="dialogVisible"
      :title="edit? '编辑-逻辑区':'新建-逻辑区'"
      custom-class="custom-dialog_500"
      :track="{
        name_zh: `基础数据/仓库相关/逻辑区管理/弹窗-${edit? '编辑': '新建'}`,
        trackName: $route.path
      }"
      @close="closeDialog"
    >
      <div class="body">
        <el-form
          ref="ruleForm"
          :model="form"
          label-width="100px"
          :rules="rules"
        >
          <el-form-item label="逻辑区编码" prop="abstractZoneCode">
            <el-input v-model="form.abstractZoneCode" :disabled="edit" />
          </el-form-item>
          <el-form-item label="逻辑区名称" prop="abstractZoneName">
            <el-input v-model="form.abstractZoneName" />
          </el-form-item>
          <el-form-item label="逻辑区类型" prop="typeCode">
            <el-select
              v-model="form.typeCode"
              placeholder="请选择"
              :disabled="edit"
            >
              <el-option
                v-for="item in typeCodeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <nh-button
            type="primary"
            :loading="loading.submitForm"
            @click="submitForm"
          >
            确 定
          </nh-button>
          <nh-button @click="close">
            取 消
          </nh-button>
        </div>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import mixin from '../mixin';
import { abstractZoneInsert, abstractZoneUpdate } from '../api';

export default {
  name: 'EditDialog',
  components: {},
  mixins: [loadingMixin, mixin],
  emits: ['onSuccess'],
  data() {
    return {
      edit: false,
      dialogVisible: false,
      loading: {
        submitForm: false,
      },
      form: {
        abstractZoneCode: null,
        abstractZoneName: null,
        typeCode: null,
      },
      rules: {
        abstractZoneCode: [
          { required: true, message: '请输入逻辑区编码', trigger: 'blur' },
        ],
        abstractZoneName: [
          { required: true, message: '请输入逻辑区名称', trigger: 'blur' },
        ],
        typeCode: [
          { required: true, message: '请选择逻辑区类型', trigger: 'change' },
        ],
      },
    };
  },
  methods: {
    async init(data) {
      this.dialogVisible = true;
      const isEdit = !!data;
      this.edit = isEdit;
      if (isEdit) {
        this.$nextTick(() => {
          this.form = { ...data };
        });
      }
    },
    async submitForm() {
      await this.$refs.ruleForm.validate();
      if (!this.edit) {
        await abstractZoneInsert(this.form);
      } else {
        await abstractZoneUpdate(this.form);
      }
      this.$message({
        type: 'success',
        message: `${this.edit ? '修改' : '添加'}成功!`,
      });
      this.$emit('onSuccess');
      this.close();
    },
    close() {
      this.dialogVisible = false;
    },
    closeDialog() {
      this.$refs.ruleForm.resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.body {
  width: 300px;
  margin: 0 auto;
}
</style>
